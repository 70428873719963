import React from 'react';
import Layout from '../../components/common/Layout';
import SignUpVerify from '../../components/from/SignUp/SignUpVerify';
import SEO from '../../components/SEO';

const SignUpVerifyPage = () => (
  <Layout lang="de">
    <SEO
      title="Registration verify nötig - Nextcloud Hosting"
      description="Registration per E-Mail abschliesen."
    />
    <SignUpVerify lang="de" />
  </Layout>
);

export default SignUpVerifyPage;
